<template>
  <div id="main-index">
    <div id="main-content" class="guardian-login-form">
      <div
        style="
          width: 80%;
          margin: 0 auto;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        "
      >
        <div></div>
        <img
          id="main-index__illustration"
          class="mx-auto pointer-events-none"
          src="../assets/images/logo.svg"
          alt="illustration"
        />
        <div>
          <div style="z-index: 100; position: relative">
            <p
              style="
                font-family: var(--sans);
                text-align: center;
                font-size: 14px;
                margin-bottom: 20px;
              "
            >
              Are you a <b>guardian</b> or a <b>child</b>?
            </p>
            <RouterLink
              replace
              to="/parent-login"
              class="guardian-login-form__button"
              style="margin-bottom: 13px;"
            >
              <span>Guardian</span>
            </RouterLink>
            <RouterLink
              replace
              to="/child-login"
              class="guardian-login-form__button"
            >
              <span>Child</span>
            </RouterLink>
            <RouterLink
              replace
              to="/register"
              class="guardian-login-form__button"
              id="register-button"
              style="margin-top: 10px; font-family: var(--fredoka)"
            >
            Register
            </RouterLink>

            <!-- <RouterLink
              replace
              to="/parent-login"
            >
              <span>Testing</span>
            </RouterLink> -->
          </div>

          <div style="margin-top: 40px; text-align: center">
            <RouterLink
              replace
              to="/terms-and-conditions"
              style="
                color: var(--main);
                text-decoration: none;
                font-size: 13px;
                text-align: center;
                font-family: var(--fredoka);
                display: inline;
              "
            >
              Terms & Conditions
            </RouterLink>
            <span
              style="
                color: #7a615a;
                display: inline;
                text-decoration: none;
                font-size: 13px;
                text-align: center;
                font-family: var(--fredoka);
              "
              >&</span
            >
            <RouterLink
              replace
              to="/privacy-policy"
              style="
                color: var(--main);
                text-decoration: none;
                font-size: 13px;
                text-align: center;
                font-family: var(--fredoka);
                display: inline;
              "
            >
              Privacy Policy
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
    <LoginFooter id="footer" />
  </div>
</template>

<script>
  import LoginFooter from "../../login/components/LoginFooter";
  import { checkLocalStorage } from "@/pages/utils/checkLocalStorage";

  export default {
    components: {
      LoginFooter,
    },
    methods: {
      openNeedHelp() {
        this.$router.replace({ query: { action: "faq" } });
        setTimeout(() => {
          this.$router.replace({ query: null });
        }, 1000);
      },
      redirect() {
        this.$router.replace({
          path: "/",
          query: {
            registration_success: true,
            registration_email: "test@test.com",
          },
        });
      },
    },
    created() {
      this.$store.state.loading = true;
      setTimeout(() => {
        this.$store.state.loading = false;
      }, 1500);

      if (checkLocalStorage("guardian")) {
        location.replace(`/guardian`);
      }

      if (checkLocalStorage("child")) {
        location.replace(`/children`);
      }
    },
  };
</script>

<style lang="scss" scoped>
  #main-index {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    background: url("../assets/images/background.svg");

    &__logo {
      height: 75px;
    }

    &__illustration {
      margin: 0 auto;
      width: 200px;
    }
  }

  .guardian-login-form {
    width: 100%;
    margin: 0 auto;
    z-index: 10;
    margin-bottom: 20px;

    &__input {
      background-color: #ebebeb;
      width: 80%;
      padding: 15px;
      border: 1px solid #d9d9d9;
      border-radius: 10px;
      outline: none;
      font-size: 14px;
      color: #444 !important;
    }

    &__title {
      margin-bottom: 40px;
      text-align: center;
      color: #7a6159;

      h1 {
        font-size: 20px;
      }

      p {
        margin-top: 25px;
        font-size: 14px;
      }
    }

    &__button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 100px;
      border: 1px solid var(--main);
      background: var(--main);
      color: white;
      outline: none;
      min-height: 50px;
      font-size: 1.1rem;
      font-family: var(--fredoka);
      text-decoration: none;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:active {
        transform: scale(0.99);
        color: white;
        background: var(--main);
      }

      &:focus {
        background-color: var(--main);
        color: white;
      }
    }

    &__forgot {
      margin: 10px 0 20px 0;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      width: 80%;
      color: rgb(180, 180, 180);
      font-size: 14px;
    }

    &__register {
      margin: 0 auto;
      margin-top: 15px;
      width: 80%;
      color: #777;
      font-size: 14px;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: space-between;

      svg {
        margin-left: 5px;
      }
    }
  }

  ::placeholder {
    color: #bebebe;
  }

  .mt {
    margin-top: 10px;
  }

  #main-content {
    flex: auto;
  }

  #footer {
    height: 25px;
    margin: 0 auto;
  }
  #register-button{
    background-color: rgb(128,96,87);
    border-color: rgb(128,96,87);
  }
</style>
